export interface HashMap<T> {
  [id: number]: T;
}

export function hashMapToArray<T>(map: HashMap<T>): T[] {
  if (!map || Object.keys(map).length < 1) {
    return [];
  }
  return Object.values(map);
}

export function arrayToHashMap<T>(array: T[]): HashMap<T> {
  const map: HashMap<T> = {};
  if (!array) {
    return map;
  }
  array.forEach((entry) => {
    map[entry['id']] = entry;
  });
  return map;
}
