import { createAction, props } from '@ngrx/store';

import { Technique } from './technique.model';

export const techniqueActionTypes = {
  FETCH_ALL: '[Technique] Fetch All',
  FETCH_ALL_FAILED: '[Technique] Fetch All Failed',
  RECEIVED: '[Technique] Received',
  CREATE: '[Technique] Create',
  CREATED: '[Technique] Created',
  DELETE: '[Technique] Delete',
  DELETE_FAILED: '[Technique] Delete Failed',
  DELETED: '[Technique] Deleted',
};

export const fetchAllTechniquesAction = createAction(
  techniqueActionTypes.FETCH_ALL
);
export const fetchAllTechniquesFailedAction = createAction(
  techniqueActionTypes.FETCH_ALL_FAILED
);
export const techniquesReceivedAction = createAction(
  techniqueActionTypes.RECEIVED,
  props<{ techniques: Technique[] }>()
);
export const createTechniqueAction = createAction(
  techniqueActionTypes.CREATE,
  props<{ technique: Technique }>()
);
export const createTechniqueFailedAction = createAction(
  techniqueActionTypes.FETCH_ALL_FAILED
);
export const techniqueCreatedAction = createAction(
  techniqueActionTypes.CREATED,
  props<{ technique: Technique }>()
);
export const deleteTechniqueAction = createAction(
  techniqueActionTypes.DELETE,
  props<{ id: number }>()
);
export const deleteTechniqueFailedAction = createAction(
  techniqueActionTypes.DELETE_FAILED
);
export const techniqueDeletedAction = createAction(
  techniqueActionTypes.DELETED,
  props<{ id: number }>()
);
