import { createAction, props } from '@ngrx/store';

import { Series } from './series.model';

export const seriesActionTypes = {
  FETCH_ALL_SERIES: '[Series] Fetch All Series',
  FETCH_ALL_SERIES_FAILED: '[Series] Fetch All Series Failed',
  SERIES_RECEIVED: '[Series] Series Received',
  CREATE_SERIES: '[Series] Create Series',
  SERIES_CREATED: '[Series] Series Created',
  DELETE_SERIES: '[Series] Delete Series',
  DELETE_SERIES_FAILED: '[Series] Delete Series Failed',
  SERIES_DELETED: '[Series] Series Deleted',
  UPDATE_SERIES: '[Series] Update Series',
  SERIES_UPDATED: '[Series] Series Updated',
  UPDATE_SERIES_FAILED: '[Series] Update Series Failed',
};

export const fetchAllSeriesAction = createAction(
  seriesActionTypes.FETCH_ALL_SERIES
);
export const fetchAllSeriesFailedAction = createAction(
  seriesActionTypes.FETCH_ALL_SERIES_FAILED
);
export const seriesReceivedAction = createAction(
  seriesActionTypes.SERIES_RECEIVED,
  props<{ series: Series[] }>()
);
export const createSeriesAction = createAction(
  seriesActionTypes.CREATE_SERIES,
  props<{ series: Series }>()
);
export const createSeriesFailedAction = createAction(
  seriesActionTypes.FETCH_ALL_SERIES_FAILED
);
export const seriesCreatedAction = createAction(
  seriesActionTypes.SERIES_CREATED,
  props<{ serie: Series }>()
);
export const deleteSeriesAction = createAction(
  seriesActionTypes.DELETE_SERIES,
  props<{ id: number }>()
);
export const deleteSeriesFailedAction = createAction(
  seriesActionTypes.DELETE_SERIES_FAILED
);
export const seriesDeletedAction = createAction(
  seriesActionTypes.SERIES_DELETED,
  props<{ id: number }>()
);
export const updateSeriesAction = createAction(
  seriesActionTypes.UPDATE_SERIES,
  props<{ id: number, series: Series }>()
);
export const seriesUpdatedAction = createAction(
  seriesActionTypes.SERIES_UPDATED,
  props<{ id: number, series: Series }>()
);

export const updateSeriesFailedAction = createAction(
  seriesActionTypes.UPDATE_SERIES_FAILED,
  props<{ id: number}>()
);

