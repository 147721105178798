import { createAction, props } from '@ngrx/store';

import { Artwork, Picture } from './artwork.model';

export const artworkActionTypes = {
  UPLOAD_IMAGES: '[Artwork] Upload Images',
  IMAGE_UPLOAD_FAILED: '[Artwork] Image Upload Failed',
  IMAGES_UPLOADED: '[Artwork] Images Uploaded',
  DELETE_IMAGES: '[Artwork] Delete Images',
  IMAGES_DELETION_FAILED: '[Artwork] Image Deletion Failed',
  IMAGES_DELETED: '[Artwork] Images Deleted',
  DELETE_ARTWORK: '[Artwork] Delete Artwork',
  ARTWORK_DELETION_FAILED: '[Artwork] Artwork Deletion Failed',
  ARTWORK_DELETED: '[Artwork] Artwork Deleted',
  UPSERT_ARTWORK: '[Artwork] Create Artwork',
  ARTWORK_UPSERTION_FAILED: '[Artwork] Artwork Creation Failed',
  ARTWORK_UPSERTED: '[Artwork] Artwork Created',
  FETCH_SERIES_ARTWORK: '[Artwork] Fetch Artwork By Series',
  FETCHING_SERIES_ARTWORK_FAILED: '[Artwork] Fetching Artwork By Series Failed',
  SERIES_ARTWORK_RECEIVED: '[Artwork] Series Artwork Received',
  FETCH_ALL_ARTWORK: '[Artwork] Fetch All Artwork',
  FETCHING_ALL_ARTWORK_FAILED: '[Artwork] Fetching All Artwork Failed',
  ALL_ARTWORK_RECEIVED: '[Artwork] All Artwork Received',
  IMAGE_LOADED: '[Artwork] Image Loaded',
  RESET_LOADED_IMAGE_COUNTER: '[Artwork] Reset Loaded Image Counter',
};

export const fetchSeriesArtworkAction = createAction(
  artworkActionTypes.FETCH_SERIES_ARTWORK,
  props<{ seriesId: number }>()
);
export const fetchingSeriesArtworkFailedAction = createAction(
  artworkActionTypes.FETCHING_SERIES_ARTWORK_FAILED
);
export const seriesArtworkReceived = createAction(
  artworkActionTypes.SERIES_ARTWORK_RECEIVED,
  props<{ pieces: Artwork[]; seriesId: number }>()
);
export const fetchAllArtworkAction = createAction(
  artworkActionTypes.FETCH_ALL_ARTWORK
);
export const fetchingAllArtworkFailedAction = createAction(
  artworkActionTypes.FETCHING_ALL_ARTWORK_FAILED
);
export const allArtworkReceived = createAction(
  artworkActionTypes.ALL_ARTWORK_RECEIVED,
  props<{ pieces: Artwork[] }>()
);
export const uploadImagesAction = createAction(
  artworkActionTypes.UPLOAD_IMAGES,
  props<{ files: File[]; slug: string, seriesId: number }>()
);
export const imagesUploadedAction = createAction(
  artworkActionTypes.IMAGES_UPLOADED,
  props<{ pictures: Picture[] }>()
);
export const deleteImagesAction = createAction(
  artworkActionTypes.DELETE_IMAGES,
  props<{ picture: Picture }>()
);
export const imageDeletionFailedAction = createAction(
  artworkActionTypes.IMAGES_DELETION_FAILED,
);
export const imagesDeletedAction = createAction(
  artworkActionTypes.IMAGES_DELETED,
);
export const imageUploadFailedAction = createAction(
  artworkActionTypes.IMAGE_UPLOAD_FAILED
);
export const deleteArtworkAction = createAction(
  artworkActionTypes.DELETE_ARTWORK,
  props<{ id: number, seriesId: number, deleteImages: boolean }>()
);
export const artworkDeletionFailedAction = createAction(
  artworkActionTypes.ARTWORK_DELETION_FAILED
);
export const artworkDeletedAction = createAction(
  artworkActionTypes.ARTWORK_DELETED
);
export const upsertArtworkAction = createAction(
  artworkActionTypes.UPSERT_ARTWORK,
  props<{ pieces: Artwork[] }>()
);
export const artworkUpsertionFailedAction = createAction(
  artworkActionTypes.ARTWORK_UPSERTION_FAILED
);
export const artworkUpsertedAction = createAction(
  artworkActionTypes.ARTWORK_UPSERTED,
  props<{ pieces: Artwork[] }>()
);
export const imageLoadedAction = createAction(
  artworkActionTypes.IMAGE_LOADED
);

export const resetLoadedIMageCounterAction = createAction(
  artworkActionTypes.RESET_LOADED_IMAGE_COUNTER
);