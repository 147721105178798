import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Config } from './config.model';

@Injectable()
export class ConfigService {
  public imageRepository: string;
  public thumbnailFolder: string;
  public optimizedFolder: string;
  public publicBucket: string;

  constructor(private http: HttpClient) {}

  async load(): Promise<void> {
    const config = await fetch(
      `${environment.apiUrl}/config/frontend`
    ).then((response) => response.json());
    Object.assign(this, config);
  }
}
