import { HttpMethod } from '@auth0/auth0-angular';

export const environment = {
  production: true,
  apiUrl: '/api',
  hmr: false,
  auth: {
    domain: 'artsy.eu.auth0.com',
    clientId: '76JCrYCAD2U6Cj7700Y7eVJYQ0JIqo0M',
    authorizationParams: {
      redirect_uri: window.location.origin + '/admin',
      audience: 'https://www.christiandecalvairac.com/api',
    },
    httpInterceptor: {
      allowedList: [
        {
          uri: '/api/images*'
        },
        {
          uri: '/api/series*',
          httpMethod: HttpMethod.Post
        },
        {
          uri: '/api/series*',
          httpMethod: HttpMethod.Delete
        },
        {
          uri: '/api/artwork*',
          httpMethod: HttpMethod.Post
        },
        {
          uri: '/api/artwork*',
          httpMethod: HttpMethod.Delete
        },
        {
          uri: '/api/techniques*',
          httpMethod: HttpMethod.Post
        },
        {
          uri: '/api/techniques*',
          httpMethod: HttpMethod.Delete
        },
      ]
    }
  },
  images: {
    baseUrl: '',
    publicBucket: '',
    originalsBucket: '',
    thumbFolder: '',
    optimizedFolder: '',
  },
};